// React imports
import React from 'react';

// Material-UI imports
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

// Gatsby imports
import { graphql } from 'gatsby';

// local imports
import { styles } from './TermsTemplate.style';
import WithTheme from 'components/WithTheme';
import Footer from 'views/Footer';

import 'fonts/roboto.css';

function TermsTemplate({
    classes,
    data: {
        markdownRemark: { frontmatter, html },
    },
    pageContext: { isNewDesign },
}) {
    return (
        <WithTheme>
            <Grid container>
                <Grid item className={classes.root} xs={isNewDesign ? 12 : 11}>
                    <div
                        className={clsx(isNewDesign && classes.contentNewDesign, !isNewDesign && classes.content)}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </Grid>
            </Grid>

            {frontmatter.footer && <Footer />}
        </WithTheme>
    );
}

export default withStyles(styles)(TermsTemplate);

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
                footer
            }
        }
    }
`;
