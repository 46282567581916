import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
            marginBottom: '10px',
            marginTop: '10px',
        },
        content: {
            color: '#3E4375',
            '& p': { color: '#6A6F95' },
            '& strong': { color: '#3E4375' },
            '& li': { color: '#6A6F95' },
        },
        contentNewDesign: {
            fontSize: '12px',
            color: '#3D4975',
            '& h2': { fontSize: '15px', fontFamily: 'Roboto !important' },
            '& h3': { fontSize: '14px', fontFamily: 'Roboto !important' },
            '& p': { color: '#3D4975', fontFamily: 'Roboto !important' },
            '& strong': { color: '#3D4975', fontFamily: 'Roboto !important' },
            '& li': { color: '#3D4975', fontFamily: 'Roboto !important' },
        },
    });
